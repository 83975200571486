<template>
  <mdb-modal
    centered
    elegant
    scrollable
    :show="mostrarModal" @close="$emit('cerrar')"
  >
    <mdb-modal-header>
      <mdb-modal-title>Exportar clientes</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      Tenemos {{ clientesCantidadExportar }} clientes
    </mdb-modal-body>
    <mdb-modal-footer class="justify-content-center">
      <mdb-btn
        flat
        dark-waves
        color="secondary"
        icon="times"
        @click="$emit('cerrar')"
      >
        Cerrar
      </mdb-btn>
      <mdb-btn
        color="primario"
        icon="file-excel"
        @click="exportar"
      >
        Exportar
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import gql from "graphql-tag";
import writeXlsxFile from 'write-excel-file'
import clienteExportarGql from "@/graphql/clienteExportar.gql";
import clientesCantidadExportarGql from "@/graphql/clienteCantidadExportar.gql";


import {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn
  } from 'mdbvue';
  export default {
    name: 'ModalExportarClientes',
    components: {
      mdbModal,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalBody,
      mdbModalFooter,
      mdbBtn
    },
    props: {
      mostrarModal: {
        type: Boolean,
        required: true,
      }
    },
    data() {
      return {
      };
    },
    methods: {
      async exportar() {
        this.$apollo
          .query({
            query: gql`${clienteExportarGql}`,
            fetchPolicy: 'network-only'
          })
          .then(async (res) => {
            const titulo = [
              {
                value: 'Clientes',
                span: 4,
                align: 'center',
                alignVertical: 'center',
                color: '#071A6A',
                fontWeight: 'bold',
                height: 30,
              },
              {value: ''},
              {value: ''},
              {value: ''},
            ];
            const cabeceras =  [
              {
                value: 'Nombre',
                fontWeight: 'bold',
                color: '#071A6A',
                backgroundColor: '#FCC626'
              },
              {
                value: 'Casillero',
                fontWeight: 'bold',
                color: '#071A6A',
                backgroundColor: '#FCC626'
              },
              {
                value: 'Teléfonos',
                fontWeight: 'bold',
                color: '#071A6A',
                backgroundColor: '#FCC626'
              },
              {
                value: 'Emails',
                fontWeight: 'bold',
                color: '#071A6A',
                backgroundColor: '#FCC626',
                width: 40,
              },
            ];

            const clientes = res.data.Cliente.map((c)=> {
              const telefonos = c.telefonos.map((t)=>{
                return t.numero
              })
              const emails = c.emails.map((e)=>{
                return e.email
              })
              return [
                {
                  type: String,
                  value: c.nombre
                },
                {
                  type: String,
                  value: c.casillero
                },
                {
                  type: String,
                  value: telefonos.join(', '),
                },
                {
                  type: String,
                  value: emails.join(', '),
                  width: 40,
                },
              ];
            });

            const data = [titulo, cabeceras, ...clientes]

            await writeXlsxFile(data, {
              // columns, // (optional) column widths, etc.
              stickyRowsCount: 2,
              fileName: 'GTi - clientes - '+ new Date().toISOString().split("T")[0] +'.xlsx'
            })
          })
      }

    },
    apollo: {
      clientesCantidadExportar() {
        return {
          query: clientesCantidadExportarGql,
          fetchPolicy: 'network-only'
        }
      }
    }
  };
</script>

<style>

</style>