<template>
  <div class="container-xl">
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Exportar</h2>
    </div>
    <ul class="lista-opciones">
      <li
        v-for="documento in documentos"
        :key="documento.nombre"
        @click="mostrarModal = documento.modal"
      >
        <div class="contenido">
          <font-awesome-icon icon="users" size="3x" />
          <span class="nombre">{{ documento.nombre }}</span>
        </div>
      </li>
    </ul>

    <ModalExportar
      :mostrar-modal="(mostrarModal === 'cliente')"
      @cerrar="mostrarModal = ''"
    />
  </div>
</template>

<script>
import ModalExportar from "@/components/ModalExportarClientes.vue"
export default {
  name: 'Exportar',
  components: {
    ModalExportar,
  },
  data () {
    return {
      mostrarModal: '',
      documentos: [
        {
          nombre: 'Clientes',
          modal: 'cliente',
          deshabilitar: false,
        }
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.lista-opciones {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  list-style: none;
  margin-block-end: 0;
  margin-top: 1rem;
  padding-inline-start: 0;

  @media screen and (min-width: 576px){flex-flow: row wrap;}

  li {
    @media screen and (min-width: 576px){width: calc(100% / 2);}
    @media screen and (min-width: 992px){width: calc(100% / 3);}
    @media screen and (min-width: 1300px){width: calc(100% / 4);}
  }
  .contenido {
    border: 1px solid #E0E2EC;
    border-radius: .125em;
    color: $terciario-sombra;
    cursor: pointer;
    margin: .75rem 1rem;
    padding: 1rem .48rem;
    text-align:center;
    transition-property: border-color,box-shadow, color;
    transition-duration: .25s;
    transition-timing-function: ease;
    user-select: none;

    &:hover {
      border-color: #C1C6DA;
      box-shadow: 3px 3px 10px #E0E2EC;
      color: $terciario-tinte;
    }
  }

  .nombre {
    display: block;
    font-weight: 500;
  }
}
</style>